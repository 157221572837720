.card {
  position: relative;
  border-radius: 5px;
  color: black;
  padding: 25px;
  background-color: white;
  margin: 20px;
  cursor: pointer;

  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  -webkit-box-shadow: 0px 10px 25px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 10px 25px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 10px 25px -10px rgba(0, 0, 0, 0.75);
}

.card:hover{
    -webkit-box-shadow: 0px 27px 45px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 27px 45px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 27px 45px -10px rgba(0, 0, 0, 0.75);
}

.center {
  text-align: center;
}

/*
.center:hover img{
    
    transform: scale(1.05);
    filter: drop-shadow(5px 5px 5px #222);
}
*/

.cardHeading {
  font-size: medium;
  font-weight: 600;
}

.header {
  height: 70px;
}

.cardDescription {
  font-weight: 100;
  padding: 20px;
}

.info ul {
  text-decoration: none;
}

.margin {
  padding-bottom: 60px;
  padding-top: 5px;
}

.bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.input {
  text-align: center;
}

.changeAmount {
  padding-bottom: 4px;
  display: flex;
  justify-content: space-between;
  color: gray;
}
.changeAmount input {
  width: 100%;
}

.sendToCart {
  background-color: beige;
  padding: 10px;
}
.sendToCart:hover {
  background-color: cornflowerblue;
  cursor: pointer;
}
/*
.button:hover{
    cursor: pointer;
    color:gray;
    filter: drop-shadow(5px 5px 5px #222);
}
*/

.dialog {
  padding: 20px;
  padding-top: 40px;
  overflow: scroll;
}

.closeIcon {
  position: absolute;
  top: 10px;
  left: 10px;
}

.closeIcon:hover {
  color: gray;
  cursor: pointer;
}

.imageInDialog {
  width: 170px;
}

.image {
  margin-bottom: 10px;
  max-width: 120px; /* width of container */
  max-height: 120px; /* height of container */
  object-fit: scale-down;
  /*filter: drop-shadow(1px 1px 1px #222);*/
}

@media only screen and (min-width: 768px) {
  .imageInDialog {
    width: 450px;
  }

  
.image {
    margin-bottom: 10px;
    width: 150px; /* width of container */
    height: 150px; /* height of container */
    object-fit: scale-down;
    /*filter: drop-shadow(1px 1px 1px #222);*/
  }
}
