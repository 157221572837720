.list{
    list-style-type: none;
    padding-left:0;
    
}

.container a{
    color:white;
}

.container{
    display: flex;
    justify-content: center;
    align-items: center;
    height:100vh;
    color:white;
}