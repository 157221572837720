.container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin:20px;
    
}
.innercontainer{
    width:90%;
    color:white;
    text-align: center;
}

.center{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 20px;
}

.text{
    
    text-align: center;
    color:white;
}

.avatar{
    width:150px !important;
    height:150px !important;
    margin:auto !important;
    margin-bottom: 15px !important;
    shape-outside: circle(50%) !important;
    text-align: justify;
}

.background{
    position:absolute;
    transform: skewX(-3deg);
    background-color:orange;
    z-index: -100;
}

@media(min-width:800px) {
    .avatar{
    
        width:150px !important;
        height:150px !important;
        float: left !important;
        margin: 15px !important;
        shape-outside: circle(50%) !important;
        text-align: justify;
    }

    .innercontainer{
        width:60%;
        color:white;
        text-align: center;
    }
    .innercontainer{
        width:60%;
        color:white;
        text-align: center;
    }
}