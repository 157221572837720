.container{
    display: flex;
    justify-content: center;
    align-items: center;
    height:100vh;
 
}
.innercontainer{
    width:60%;
}

.center{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 20px;
}

.text{
    
    text-align: center;
    color:white;
}