

.center{
    text-align: center;
}

.categorybox{
    width:70%;
}


.info ul{
    text-decoration: none;
    
}

.center{
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.categories{
    width: 60%;
    text-align: left;
}


