
.container{
    padding-left:8px;
    padding-right:8px;
}
.center{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.money{
    text-align: right;
    padding-left:40px;
}

.sum{
    border-top: solid;
 
}

.sum td{
    padding-top: 20px;
}


.sumTable{
    border-collapse: collapse;
    
}

.sumTable tr{
    border-bottom: solid;
    border-top:solid;
    border-width: 1px;
    border-color: lightgray;
}


.delete{
    padding-left:15px;
}

.delete:hover{
    color:darkgray;
    cursor: pointer;
}

.header{
    position: fixed;
}

.icon{
    position: relative;
    top:5px;
}

.button{
    width:90%;
    margin-top:10px !important;
}

.dialogOff{
    display:none;
}

.dialogOn{
    display: block;
    
}

.boxSize{
    width:80%;
}

.error{
    color:red;
}

@media(min-width:800px) {
    .container{
        
    }
    .sumTable{
        border-collapse: collapse;
        border-style:none;
    }
}